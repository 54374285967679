import React from "react"

class Author extends React.Component {
    render() {
        return (
            <div className="footer">
                <hr/>
                Författare: Ola Montán
            </div>
        )
    }
}

export default Author
