import React, {Component} from "react"

class TypKnapp extends Component {
	render() {
		return (
			<span className='kryssrutor kryssruta'>
                    <input
						type="checkbox"
						checked={this.props.checked}
						value={this.props.value}
						onChange={() => {this.props.handle(this.props.value)}}
					/>
				{this.props.text}
			</span>
		)
	}
}

export default TypKnapp
