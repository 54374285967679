import React, {Fragment} from "react"

class ConfigLine extends React.Component {

    constructor() {
        super()
        this.handleDelete = this.handleDelete.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
    }

    handleDelete(value) {
        this.props.handleDeleteLine(value)
    }

    handleEdit(value) {
        this.props.handleEditLine(value)
    }

    encodeEntities(text) {
        return text
    }

    render() {
        const id = this.props.line.seq
        const xClass = (this.props.line.changed ? "modified" : "") + (this.props.line.deleted ? " deleted" : "")
        //	my $xClass = $line->{changed} ? "modified" : "";
        // 	$xClass .= " deleted" if $line->{deleted};

        return (
            <Fragment>
                <div className='filter'>
                    <span className='filter-icon' onClick={() => {this.handleDelete(id)}}><img id={'filter_dele_' + id} src='static/assets/img/remove-12.png' /></span>
                    <span className='filter-icon' onClick={() => {this.handleEdit(id)}}><img id={'filter_edit_' +id} src='static/assets/img/edit-12.png'/></span>
                    <span className={'filter-typ ' + xClass} style={{textTransform: 'capitalize'}}>{this.props.line.visibleName}</span>
                    <input type="hidden" name="" value="sender" id={'filter_typd_' + id}/>
                    {/*hidden(-id => "filter_typd_{id}", -value => $line->{type}), "*/}
                    <span className={'filter-action ' + xClass} id={'filter_acti_' + id}>{this.props.line.action}</span>
                    <span className={'filter-text ' + xClass} id={'filter_text_' + id}>{this.encodeEntities(this.props.line.value)}</span>
                </div>
            </Fragment>

        )
    }
}

export default ConfigLine
