import React from "react"
import Rubrik from "../../snippet/Rubrik";

class Check extends React.Component {
	render() {
		return (
			<main>
				<Rubrik rubrik={"Kolla regler mot ett brev"}/>
				<div>
					... Check
				</div>
			</main>
		)
	}
}

export default Check
