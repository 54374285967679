import React from "react"
import TidKnapp from "./TidKnapp";

class Tidknappar extends React.Component {
	constructor() {
		super()
		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(value) {
		this.props.handle(value)
	}

	render() {
		return (
			<div className='tider'>
				<TidKnapp text={"Idag"} value={1} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"2 dag"} value={2} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"3 dar"} value={3} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"4 dar"} value={4} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"5 dar"} value={5} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"6 dar"} value={6} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"1 vecka"} value={7} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"1 månad"} value={30} aktuell={this.props.days} handle={this.handleChange}/>
				<TidKnapp text={"1 år"} value={365} aktuell={this.props.days} handle={this.handleChange}/>
			</div>
		)
	}
}

export default Tidknappar
