import React from "react"

class DebugLog extends React.Component {
    render() {
        return (
            <div className={"helptext"}>
                <pre>{JSON.stringify(this.props.value, null, 2) }</pre>
            </div>
        )
    }
}

export default DebugLog
