import React from "react"
import Header from "./components/page/Header"
import Content from "./components/page/Content"
import Footer from "./components/page/Footer"
import Cookies from "js-cookie";

class App extends React.Component {
	constructor() {
		super();
		this.handleMenuSelect = this.handleMenuSelect.bind(this)

		this.state = {
			page: "" // Används inte direkt, men triggar rendering av Content
		}
	}

	changeUrl(page, url) {
		if (typeof (history.pushState) != "undefined") {
			const obj = {Page: page, Url: url};
			history.pushState(obj, obj.Page, obj.Url);
		} else {
			window.location.href = "homePage";
			// alert("Browser does not support HTML5.");
		}
	}

	handleMenuSelect(item) {
		this.setState(prevState => prevState.page = item)
		this.changeUrl("Mail Admin", item)
	}

	render() {
		return (
			<div className='center'>
				<Header handleMenuSelect={this.handleMenuSelect}/>
				<Content/>
				<Footer/>
			</div>
		)
	}
}

export default App
