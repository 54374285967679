import React from "react"

class MailLogLine extends React.Component {
    render() {
        return (
            <tr className="listrad">
                <td className="listkolumn" nowrap="">{this.props.line.type}</td>
                <td className="listkolumn" nowrap="">{this.props.line.timestamp}</td>
                <td className="listkolumn" nowrap="">{this.props.line.fromDomain}</td>
                <td className="listkolumn" nowrap="">{this.props.line.toAddress}</td>
                <td className="listkolumn" nowrap="">{this.props.line.ip}</td>
                <td className="listkolumn" nowrap="">{this.props.line.reason}</td>
            </tr>
        )
    }
}

export default MailLogLine
