import React from "react"

class Rubrik extends React.Component {
	render() {
		return (
			<h2>
				{this.props.rubrik}
			</h2>
		)
	}
}

export default Rubrik
