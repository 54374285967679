import React from "react"
import Rubrik from "../../snippet/Rubrik";
import DebugLog from "../../snippet/DebugLog";
import Tidknappar from "../element/Tidknappar";
import Typknappar from "../element/Typknappar";
import Filterknappar from "../element/Filterknappar";
import MailLogLine from "../../snippet/MailLogLine";
import {getFromServer} from "../../utils/backend";

class Lista extends React.Component {

	constructor() {
		super();
		this.updateMailLog = this.updateMailLog.bind(this)
		this.handleDayChange = this.handleDayChange.bind(this)
		this.handleTypeChange = this.handleTypeChange.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
	}

	componentDidMount() {
		this.getMailLog(this.props.logs.days, this.updateMailLog)
	}

	getMailLog(days, handler) {
		this.props.handle("setLoading", true);
		getFromServer("/log/d" + days, handler, {
			"totalCount": 0,
			"fromDate": "",
			"toDate": "error",
			"counts": {
				"Grey": 0,
				"Reject": 0,
				"Black": 0,
				"Content": 0
			},
			"lines": []
		})
	}

	updateMailLog(logg) {
		this.props.handle("logSetLogg", logg);
	}

	handleDayChange(value) {
		this.props.handle("logSetDays", value)
		this.getMailLog(value, this.updateMailLog)
	}

	handleTypeChange(value) {
		this.props.handle("logToggleType", value)
	}

	handleFilterChange(value) {
		this.props.handle("logChangeFilter", value)
	}

	filteredLogg() {
		const filter = this.props.logs.filter

		return this.props.logs.maillogg.lines
			.filter(
				(line) => {
					return filter === "Alla" || line.toAddress.toLowerCase().indexOf(filter.toLowerCase()) >= 0
				})
			.filter(
				(line) => {
					const sender = this.props.logs.types.sender && line.type === "Black"
					const content = this.props.logs.types.content && line.type === "Content"
					const greylist = this.props.logs.types.greylist && line.type === "Grey"
					const rejected = this.props.logs.types.rejected && line.type === "Reject"
					const spam = this.props.logs.types.spam && line.type === "Spam"
					return sender || content || greylist || rejected || spam
				}
			)
	}

	render() {
		const first = this.props.logs.maillogg.fromDate
		const last = this.props.logs.maillogg.toDate
		const logg = this.filteredLogg()

		if (this.props.loading) {
			return <h1>Please wait...</h1>
		}
		return (
			<main>
				<Rubrik rubrik={"Loggar"}/>
				<div>Period: {first} – {last}</div>
				<Tidknappar days={this.props.logs.days} handle={this.handleDayChange}/>
				<Typknappar types={this.props.logs.types} handle={this.handleTypeChange}/>
				<Filterknappar filter={this.props.logs.filter} handle={this.handleFilterChange} name="Filterknapp"/>
				<div className='lista'>

					<div className='totalantal' id='summa'>Antal rader: {logg.length}</div>
					<table id='lista'>
						<tbody>
						<tr>
							<td className='listrubrik'>Typ</td>
							<td className='listrubrik'>Tidpunkt</td>
							<td className='listrubrik'>Från</td>
							{/*<td className='listrubrik'><span>Från</span><span className='compress'><img src='img/compress-16' onClick='compressKlick();'/></span></td>*/}
							<td className='listrubrik'>Till</td>
							<td className='listrubrik'>IP</td>
							<td className='listrubrik'>Orsak</td>
						</tr>
						{logg.map(value => <MailLogLine line={value} key={value.timestamp}/>)}

						</tbody>
					</table>
				</div>

				{/*<DebugLog value={this.props.logs}/>*/}
			</main>
		)
	}
}

export default Lista
