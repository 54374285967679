import React, {Component} from "react"

class Menyval extends Component {
	render() {
		const currUrl = window.location.pathname.substring(1)
		const selected = (currUrl === this.props.uri) ? " selected" : "";

		return (
			<span className={"menuitem" + selected} onClick={() => {this.props.handleMenuSelect(this.props.uri)}}>
				<img src={"/static/assets/img/" + this.props.image} alt={""}/>
			</span>
		)
	}
}

export default Menyval
