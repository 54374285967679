import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from 'react-router-dom';
import ErrorContainer from './containers/ErrorContainer/ErrorContainer';
import App from "./App"

ReactDOM.render(
	<BrowserRouter basename={"/"}>
		<ErrorContainer>
			<App/>
		</ErrorContainer>
	</BrowserRouter>,
	document.getElementById('root')
)
