import React from "react"

class EditDialog extends React.Component {
	constructor() {
		super()
	}

	// React.statics = {
	// 	someMethod: function() {
	// 		//...
	// 	}
	// };

	saveDialog(dialog) {
		const typeElem = document.getElementById("type")
		const type = typeElem.value;
		const visibleName = typeElem.options[typeElem.selectedIndex].text
		const action = document.getElementById("action").value;
		const regel = document.getElementById("regel").value;
		const radnr = document.getElementById("radnr").value;

		var line
		if (radnr > 0) {
			line = this.props.config.list[radnr - 1]
			line.type = type
			line.saveType = type
			line.visibleName = visibleName
			line.action = action
			line.value = regel
		} else {
			line = {
				seq: this.props.config.list.length + 1,
				action: action,
				saveType: regel,
				type: regel,
				value: regel,
				visibleName: visibleName,
				changed: true,
				deleted: false
			}
		}
		console.log("::: LLL ", line)

		this.props.closeEditDialog(radnr, line)
	}


	render() {
		return (
			<div id="editDialog" className="edit_dialog">
				<div className="edit_dialog_content">
					<input type="hidden" name="radnr" value="" id="radnr"/>
					<div className="filter-action">
						<select name="type" id="type">
							<option value="">-</option>
							<option value="sender">Sender</option>
							<option value="headerFrom">From</option>
							<option value="headerTo">To</option>
							<option value="headerSubject">Subject</option>
							<option value="body">Body</option>
							<option value="headerCustom">Custom</option>
						</select>
					</div>
					<div className="filter-action">
						<select name="action" id="action">
							<option value="-">-</option>
							<option value="OK">OK</option>
							<option value="REJECT">REJECT</option>
						</select>
					</div>
					<div className="filter-text">
						<input type="text" name="regel" size="30" maxLength="200" id="regel"/>
					</div>
					<input type="submit" name="editButton" value="OK" onClick={() => {
						this.saveDialog("editDialog")
					}}/>
					<input type="button" value="Avbryt" onClick={() => {
						this.props.closeEditDialog(0)
					}}/>
				</div>
			</div>
		)
	}
}

export default EditDialog
