import React from "react"
import Menyval from "./Menyval";

class Meny extends React.Component {
	render() {
		return (
			<div className='topmenu'>
				{this.props.menyval.map(value => <Menyval uri={value.link} image={value.icon} key={value.link} handleMenuSelect={this.props.handleMenuSelect}/>)}
			</div>
		)
	}
}

export default Meny
