export function getFromServer(url, handler, errorResponse) {
	console.log("Backend URL", process.env.REACT_APP_BACKEND_SERVER + url)
	fetch(process.env.REACT_APP_BACKEND_SERVER + url, {
			headers: {
				'Authorization': 'kQDHzGTXEx8h6MLchprWDTSpXBEnurZ2cypzxFyUeLceK4mXhzMHr8AF7DaXreQD',
			}
		})
		.then(res => res.json())
		.then(
			(result) => {
				handler(result);
			},
			(error) => {
				handler(errorResponse)
			}
		)
}

export function sendToServer(url, method, body, handler, errorResponse) {
	console.log("Backend URL", process.env.REACT_APP_BACKEND_SERVER + url)
	fetch(process.env.REACT_APP_BACKEND_SERVER + url, {
		method: method,
		headers: {
			'Authorization': 'kQDHzGTXEx8h6MLchprWDTSpXBEnurZ2cypzxFyUeLceK4mXhzMHr8AF7DaXreQD',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	})
		.then(res => res.json())
		.then(
			(result) => {
				handler(result);
			},
			(error) => {
				handler(errorResponse)
			}
		)

}
