import React from "react"

class Titel extends React.Component {
	render() {
		return (
			<h1>
				Administrera e-postservern Montán
			</h1>
		)
	}
}

export default Titel
