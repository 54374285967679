import React, {Component} from 'react';

import './ErrorContainer.css';

class ErrorContainer extends Component {
  state = {
    code: null,
    message: null,
    errorInfo: null
  };

  componentDidCatch({code, message}, errorInfo) {
    console.log('code', code);
    console.log('message', message);
    this.setState({code, message, errorInfo});
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-container">
          <div className="error-wrapper">
            <div className="error-header-wrapper"/>
            <div className="error-content-wrapper">
              <h1>It seems like something went wrong!</h1>
              <h5>{this.state.code}</h5>
              {/*<p>{this.props.t(this.state.message)}</p>*/}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorContainer

