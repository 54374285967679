import React, {Component, Fragment} from 'react';
import Lista from "./subpage/Lista";
import Edit from "./subpage/Edit";
import EditDialog from "../snippet/EditDialog";
import Statistik from "./subpage/Statistik";
import Purge from "./subpage/Purge";
import Check from "./subpage/Check";
import Cookies from 'js-cookie';

class Content extends Component {

	constructor() {
		super();
		this.handleChildEvent = this.handleChildEvent.bind(this)

		this.auth = Cookies.get('admin_auth_security')

		this.state = {
			logs: {
				loading: true,
				days: 7,
				types: {
					sender: true,
					content: true,
					greylist: true,
					rejected: false,
					spam: false
				},
				filter: "Alla",
				maillogg: {"totalCount": 0, "lines": []}
			},
			config: {
				loading: true,
				statusActive: false,
				list: []
			}
		}

	}

	handleChildEvent(event, value) {
		console.log("::: handleChildEvent", event, value)
		switch (event) {
			case "setLoading":
				this.setState(prevState => prevState.logs.loading = value)
				break;

			case "logSetLogg" :
				this.setState(prevState => prevState.logs.loading = false)
				this.setState(prevState => prevState.logs.maillogg = value)
				break
			case "logSetDays":
				this.setState(prevState => prevState.logs.loading = true)
				this.setState(prevState => prevState.logs.days = value)
				break
			case "logChangeFilter":
				this.setState(prevState => prevState.logs.filter = value)
				break
			case "logToggleType":
				switch (value) {
					case "sender":
						this.setState(prevState => prevState.logs.types.sender = !prevState.logs.types.sender)
						break
					case "content":
						this.setState(prevState => prevState.logs.types.content = !prevState.logs.types.content)
						break
					case "greylist":
						this.setState(prevState => prevState.logs.types.greylist = !prevState.logs.types.greylist)
						break
					case "rejected":
						this.setState(prevState => prevState.logs.types.rejected = !prevState.logs.types.rejected)
						break
					case "spam":
						this.setState(prevState => prevState.logs.types.spam = !prevState.logs.types.spam)
						break
					default:
				}
				break

			case "configSetList":
				this.setState(prevState => prevState.config.loading = false)
				this.setState(prevState => prevState.config.list = value)
				break
			case "configDeleteLine":
				this.setState(prevState => prevState.config.statusActive = false)
				this.setState(prevState => prevState.config.list[value-1].deleted = ! prevState.config.list[value-1].deleted)
				break
			case "configModifiedLine":
				this.setState(prevState => prevState.config.statusActive = false)
				this.setState(prevState => prevState.config.list[value-1].changed = true)
				break
			case "configAddLine":
				this.setState(prevState => prevState.config.statusActive = false)
				this.setState(prevState => prevState.config.list.push(value))
				break
			case "configShowStatusMessage":
				this.setState(prevState => prevState.config.statusActive = value)
				break
		}
	}

	render() {
		const xyz = "SdVdP9BNYrfxfaJ7vuFANw3m2ZB4L4seU7gcRLjWLdZLFDDWZk"
		if (this.auth !== xyz) {
			return <div>Error</div>
		} else {
			const currUrl = window.location.pathname.substring(1)
			switch (currUrl) {
				case "edit":
					return <Edit
						config={this.state.config}
						handle={this.handleChildEvent}
					/>

				case "lista":
					return <Lista
						logs={this.state.logs}
						handle={this.handleChildEvent}
					/>

				case "statistik":
					return <Statistik
						handle={this.handleChildEvent}
					/>

				case "purge":
					return <Purge
						handle={this.handleChildEvent}
					/>

				case "check":
					return <Check
						handle={this.handleChildEvent}
					/>

				default:
					window.location.href = "/lista"
					return <Fragment></Fragment>
			}
		}
	}
}

export default Content
