import React from "react"
import Author from "../snippet/Author";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <Author/>
            </footer>
        )
    }
}

export default Footer
