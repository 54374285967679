import React from "react"
import Rubrik from "../../snippet/Rubrik";
import Meny from "../../snippet/Meny";
import ConfigLine from "../../snippet/ConfigLine";
import {getFromServer, sendToServer} from "../../utils/backend";
import DebugLog from "../../snippet/DebugLog";
import EditDialog from "../../snippet/EditDialog";
import BatchDialog from "../../snippet/BatchDialog";

class Edit extends React.Component {
	constructor() {
		super();
		this.handleSubMenuSelect = this.handleSubMenuSelect.bind(this)
		this.handleDeleteLine = this.handleDeleteLine.bind(this)
		this.handleEditLine = this.handleEditLine.bind(this)
		this.closeEditDialog = this.closeEditDialog.bind(this)
		this.compareLines = this.compareLines.bind(this)
		this.compareStrings = this.compareStrings.bind(this)
		this.typeValue = this.typeValue.bind(this)
		this.reloadedConfigList = this.reloadedConfigList.bind(this)
		this.savedConfigFile = this.savedConfigFile.bind(this)
		this.reloadedServer = this.reloadedServer.bind(this)
	}

	componentDidMount() {
		if (this.props.config.loading) {
			this.reloadConfigList()
		}
	}

	reloadConfigList() {
		this.props.config.loading = true
		getFromServer("/config", this.reloadedConfigList, [
			{
				"seq": 1,
				"type": "sender",
				"value": "ERROR",
				"action": "ERROR",
				"changed": false,
				"deleted": false,
				"saveType": "sender",
				"visibleName": "sender"
			}

		])
	}

	reloadedConfigList(list) {
		this.props.handle("configSetList", list);
	}

	saveConfigList() {
		this.props.handle("configShowStatusMessage", true);
		sendToServer("/config", "PUT", this.props.config.list, this.savedConfigFile, [
			{
				"seq": 1,
				"type": "sender",
				"value": "ERROR",
				"action": "ERROR",
				"changed": false,
				"deleted": false,
				"saveType": "sender",
				"visibleName": "sender"
			}

		])
	}

	savedConfigFile(list) {
		this.props.handle("configSetList", list);
		document.getElementById("resultat").innerHTML = "<div class='status'>### Sparat alla ändringar.</div>"
	}

	reloadServer() {
		this.props.handle("configShowStatusMessage", true);
		sendToServer("/config/reload", "PUT", "", this.reloadedServer, ["ERROR calling server"])
	}

	reloadedServer(response) {
		var text = "<div class='status'>### Laddar om servern.</div>"
		response.map(line => text += line + "<br/>")
		text += "<div class='status'>Servern är nu omladdad.</div>"
		document.getElementById("resultat").innerHTML = text
	}

	handleSubMenuSelect(value) {
		switch (value) {
			case "reset":
				this.props.handle("configShowStatusMessage", false);
				this.reloadConfigList();
				break;

			case "add-one":
				this.showEditDialog(0, "", "", "REJECT", "")
				break;

			case "add-multi":
				console.log("Not implemented yet")
				break

			case "save":
				this.saveConfigList()
				break

			case "reload":
				this.reloadServer()
				break
		}
	}

	handleDeleteLine(value) {
		this.props.handle("configDeleteLine", value);
	}

	handleEditLine(radnr) {
		const modal = document.getElementById('editDialog');
		modal.style.display = "block";

		const line = this.props.config.list[radnr - 1]
		this.showEditDialog(radnr, line.type, line.visibleName, line.action, line.value)
	}

	showEditDialog(radnr, type, visibleName, action, regel) {
		const modal = document.getElementById('editDialog');
		modal.style.display = "block";

		document.getElementById("type").value = type;
		document.getElementById("action").value = action;
		document.getElementById("regel").value = regel;
		document.getElementById("radnr").value = radnr;
	}

	closeEditDialog(radnr, line) {
		const modal = document.getElementById('editDialog');
		modal.style.display = "none";

		if (radnr > 0) {
			this.props.handle("configModifiedLine", radnr);
		} else if (line != null) {
			this.props.handle("configAddLine", line);
		}
	}

	compareStrings(a, b) {
		var x = a.toLowerCase()
		var y = b.toLowerCase()
		if (x < y) return -1
		if (x > y) return 1
		return 0;
	}

	typeValue(type) {
		switch (type) {
			case "sender":
				return 1
			case "headerFrom":
				return 2
			case "headerSubject":
				return 3
			case "headerTo":
				return 4
			case "headerCustom":
				return 5
			case "body":
				return 6
			default:
				return 99
		}
	}

	compareLines(a, b) {
		if (a.changed != b.changed) return b.changed - a.changed
		if (a.deleted != b.deleted) return b.deleted - a.deleted
		if (a.type != b.type) return this.typeValue(a.type) - this.typeValue(b.type)
		if (a.action != b.action) return this.compareStrings(a.action, b.action)
		return this.compareStrings(a.value, b.value)
	}

	render() {
		var Content
		if (this.props.config.loading) {
			Content = <h1>Please wait...</h1>
		} else {
			const lista = [...this.props.config.list];
			Content =
				<div className='edit' id='regelLista'>
					{lista
						.sort(this.compareLines)
						.map(
							line => <ConfigLine
								line={line}
								key={line.seq}
								handleDeleteLine={this.handleDeleteLine}
								handleEditLine={this.handleEditLine}
							/>)}

					<DebugLog value={this.props.config}/>
				</div>
		}
		return (
			<main>
				<Rubrik rubrik={"Redigera regler"}/>
				<EditDialog config={this.props.config} closeEditDialog={this.closeEditDialog}/>
				{this.props.config.statusActive ?
					<div className="resultat" id={"resultat"}></div>
					: ""
				}
				<BatchDialog/>
				<Meny
					handleMenuSelect={this.handleSubMenuSelect}
					menyval={[
						{
							"icon": "reset-25.png",
							"link": "reset"
						},
						{
							"icon": "add-25.png",
							"link": "add-one"
						},
						{
							"icon": "batch-25.png",
							"link": "add-multi"
						},
						{
							"icon": "save-25.png",
							"link": "save"
						},
						{
							"icon": "reload-25.png",
							"link": "reload"
						}
					]}/>
				{Content}
			</main>
		)
	}
}

export default Edit
