import React, {Component} from "react"

class FilterKnapp extends Component {
	render() {
		return (
			<span className='kryssrutor'>
                    <input
						type="radio"
						name={this.props.name}
						value={this.props.value}
						checked={this.props.default === this.props.value}
						readOnly
					/>
				{this.props.value}
			</span>
		)
	}
}

export default FilterKnapp
