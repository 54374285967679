import React, {Component} from "react"

class TidKnapp extends Component {
	render() {
		const checked = this.props.aktuell === this.props.value ? "tid_checked" : ""
		return (
			<span
				className={"tid " + checked}
				onClick={() => {this.props.handle(this.props.value)}}>
				{this.props.text}
			</span>
		)
	}
}

export default TidKnapp
