import React from "react"
import Rubrik from "../../snippet/Rubrik";

class Statistik extends React.Component {
	render() {
		return (
			<main>
				<Rubrik rubrik={"Statistik"}/>
				<div>
					... Statistik
				</div>
			</main>
		)
	}
}

export default Statistik
