import React from "react"
import TypKnapp from "./TypKnapp";

class Typknappar extends React.Component {
	constructor() {
		super()
		this.handleChange = this.handleChange.bind(this)

	}

	handleChange(value) {
		this.props.handle(value)
	}

	render() {
		return (
			<div className='tider'>
				<TypKnapp text={"Svartlistade"} value={"sender"} checked={this.props.types.sender} handle={this.handleChange}/>
				<TypKnapp text={"Innehåll"} value={"content"} checked={this.props.types.content} handle={this.handleChange}/>
				<TypKnapp text={"Grålistat"} value={"greylist"} checked={this.props.types.greylist} handle={this.handleChange}/>
				<TypKnapp text={"Avvisat"} value={"rejected"} checked={this.props.types.rejected} handle={this.handleChange}/>
				<TypKnapp text={"Spam"} value={"spam"} checked={this.props.types.spam} handle={this.handleChange}/>
			</div>
		)
	}
}

export default Typknappar
