import React from "react"

class BatchDialog extends React.Component {
	constructor() {
		super()
		this.closeDialog = this.closeDialog.bind(this)
	}

	closeDialog(value) {
	}

	render() {
		return (
			<div id="batchDialog" className="edit_dialog">
				<div className="edit_dialog_content">
					<div className="filter-text">
						<textarea name="batch" rows="10" cols="30" id="batch"></textarea>
					</div>
					<input type="submit" name="batchButton" value="OK"/><input type="button" value="Avbryt" onClick={() => {this.closeDialog("editDialog")}}/>
				</div>
			</div>
		)
	}
}

export default BatchDialog
