import React from "react"
import Version from "../snippet/Version";
import Titel from "../snippet/Titel";
import Meny from "../snippet/Meny";

class Header extends React.Component {
	render() {
		return (
			<header id="headerElement">
				<Titel/>
				<Version/>
				<Meny
					handleMenuSelect={this.props.handleMenuSelect}
					menyval={[
					{
						"icon": "lists-25.png",
						"link": "lista"
					},
					{
						"icon": "admin-25.png",
						"link": "edit"
					},
					{
						"icon": "statistics-25.png",
						"link": "statistik"
					},
					{
						"icon": "recycle-25.png",
						"link": "purge"
					},
					{
						"icon": "check-25.png",
						"link": "check"
					}
				]}/>
			</header>
		)
	}
}

export default Header
