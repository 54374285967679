import React from "react"
import Rubrik from "../../snippet/Rubrik";

class Purge extends React.Component {
	render() {
		return (
			<main>
				<Rubrik rubrik={"Rensa i adressfilter"}/>
				<div>
					... Purge
				</div>
			</main>
		)
	}
}

export default Purge
