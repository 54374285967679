import React from "react"
import FilterKnapp from "./FilterKnapp";

class Filterknappar extends React.Component {
	handleChange(event) {
		this.props.handle(event.target.value)
	}

	render() {
		return (
			<div className='tider' onChange={event => this.handleChange(event)}>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Alla"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Ola"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Merly.Coma"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Henrik"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Sarah"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Lily"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Harakarr"}/>
				<FilterKnapp name={this.props.name} default={this.props.filter} value={"Alert"}/>
			</div>
		)
	}
}

export default Filterknappar
