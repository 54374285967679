import React from "react"
import {getFromServer} from "../utils/backend";

class Version extends React.Component {
	constructor() {
		super()
		this.setVersion = this.setVersion.bind(this)
		this.state = {
			serverVersion: "?.?.?"
		}
	}

	setVersion(info) {
		this.setState({
			serverVersion: info.version
		})
	}

	componentDidMount() {
		getFromServer("/info", this.setVersion, {"version":"error"})
	}

	render() {
		return (
			<div className='version'>
				{process.env.REACT_APP_VERSION} / {this.state.serverVersion}
			</div>
		)
	}
}

export default Version
